import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import projects from "../data/projects"

const title = "Projects"

const Container = styled.div`
  margin-top: 60px;
  background: ${props => props.background};
  padding: 0 20px 20px 20px;
  width: calc(100% + 40px); // twice the value of the parent's padding
  margin-left: -20px; // -1 * parent's padding

  @media all and (min-width: 992px) {
    margin-top: 0;
    padding: 0 80px 20px 80px;
  }
`

const StyledImg = styled(Img)`
  width: calc(100% + 40px); // twice the value of the parent's padding
  margin-left: -20px; // -1 * parent's padding
  height: 100%;

  @media all and (min-width: 992px) {
    width: calc(100% + 160px);
    height: 65vh;
    margin-left: -80px;
  }
`

const StyledH4 = styled.h4`
  margin-top: 80px;
  margin-bottom: 0;
  color: ${props => props.theme.headerText};
`

const BottomNav = styled.div`
  margin: 26px 0 80px;
  span {
    font-size: 14px;
    color: white;
  }
`

const Projects = ({ pageContext: { project } }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "projects" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  `)

  const img = data.allFile.edges.find(
    ({ node }) => node.relativePath === project.src
  ).node

  let index = findIndexByKey(projects, "id", project.id)

  return (
    <Layout activeMenu={title} activeSubMenu={project.id}>
      <SEO title={title} />
      <Container id={project.id} background={project.background}>
        <StyledImg
          fluid={img.childImageSharp.fluid}
          alt={project.title}
          imgStyle={{
            objectPosition:
              project.id === "guinness-taste-perfection" ? "top center" : "center center",
          }}
        />
        <StyledH4>{project.title}</StyledH4>
        <p>{project.subtitle}</p>
        <p dangerouslySetInnerHTML={{ __html: project.content }} />
        <BottomNav>
          {index === 0 ? (
            <Link to={`/projects/${projects[1].id}`}>
              <span>next</span>
            </Link>
          ) : (
            <div>
              <Link to={`/projects/${projects[index - 1].id}`}>
                <span>previous </span>
              </Link>
              {index < projects.length - 1 ? (
                <Link to={`/projects/${projects[index + 1].id}`}>
                  <span>/ next</span>
                </Link>
              ) : null}
            </div>
          )}
        </BottomNav>
      </Container>
    </Layout>
  )
}

export default Projects

export function findIndexByKey(array, key, value) {
  for (var i = 0; i < array.length; i++) {
    if (array[i][key] === value) {
      return i
    }
  }
  return null
}
